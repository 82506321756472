import React from 'react'
import { Alert } from 'react-bootstrap'
import { SimpleTabs } from '../../../components/Form'
import Layout from '../../../components/Layout'
import Spinner from '../../../components/spinner'
import { useGetCompanyInfoById, useGetuserDetailsQuery } from '../../../utils/api'
import CompanyBranding from './CompanyBranding'

const MySettings = () => {
  const getUserProfile = useGetuserDetailsQuery()
  const getCompanyInfoById = useGetCompanyInfoById()
  const [tabSelectedIndex, setTabSelectedIndex] = React.useState(0)
  const filterBaseOnTab = (e) => {
    switch (e.target.innerText) {
      case 'Company Branding':
        setTabSelectedIndex(0)
        break
      case 'Marketplace':
        setTabSelectedIndex(1)
        break
      case 'Email Notifications':
        setTabSelectedIndex(2)
        break
      default:
        break
    }
  }

  const switchTabContent = (index) => {
    switch (index) {
      case 0:
        return (
          <CompanyBranding/>
        )
      case 1:
        return (
          <i className="p-3 text-muted">Coming soon.</i>
        )
      case 2:
        return (
          <i className="p-3 text-muted">Coming soon.</i>
        )
    }
  }
  return (
    <Layout>
      <Layout.Title>
          <h5>Settings</h5>
      </Layout.Title>

      <Layout.CardGrey className="my-account-card card card-grey">
        <SimpleTabs
          elements={[
            { label: 'Company Branding', onClick: filterBaseOnTab },
            { label: 'Marketplace', onClick: filterBaseOnTab, disabled: true },
            { label: 'Email Notifications', onClick: filterBaseOnTab, disabled: true }
          ]}
          selectedIndex={tabSelectedIndex}
          size="md"
        />
      {switchTabContent(tabSelectedIndex)}
        {(getCompanyInfoById.isLoading || getUserProfile.isLoading) && (
            <div className="d-flex justify-content-center p-5">
              <Spinner />
            </div>
        )}
          {getCompanyInfoById.isError && (
            <Alert variant="danger" className="mb-0 mt-3 text-center">
              {'' + getCompanyInfoById.error.message}
            </Alert>
          )}
      </Layout.CardGrey>
    </Layout>
  )
}

export default MySettings
